import 'core-js/stable/index.js';
import { init, load } from '../lib/router.js';

load('menu-element');
load('cart-counter-icon-element');
load('hamburger-icon-element');
load('header-section');
load('banner-element');
load('micromodal.css');
load('meta-pixel');
load('fullstory');
load('attentive-pixel');
load('banner-video-element');
load('cart-init');
load('sentry');
load('dynamic-header-script');
load('sale-banners');
load('product-carousel');
load('auth');
load('collection-view-observer');
load('sticky-product');
load('loading-element');
load('image-element');
load('product-card');
load('add-to-cart-element');
load('add-to-cart-bundle');
load('add-to-cart-button');
load('add-to-cart-notify-me-button');
load('alert-element');
load('configurable-review-element');
load('custom-attribute-element');
load('notify-me-modal-element');
load('price-element');
load('product-element');
load('product-nav-element');
load('product-option-element');
load('product-option-group-element');
load('product-price-element');
load('product-quote-element');
load('product-step-element');
load('product-steps-element');
load('product-variant-select-element');
load('sticky-cta-element');
load('variant-element');
load('share-element');
load('product-detail-countdown-timer-element');
load('installment-payment-message-element');
load('accordion-element');
load('accordion-item-element');
load('help-widget-element');
load('help-widget-modal-element');
load('variant-option-group-element');
load('yotpo-avatar-element');
load('yotpo-reviews-detail-actions-element');
load('yotpo-review-detail-element');
load('yotpo-review-element');
load('scrollable-table-element');
load('select-option-button-element');
load('product-discount-message-element');
load('product-rating-stars-element');
load('product-variant-selector-modal-element');
load('variant-options-element');
load('shopify-search-input-element');
load('search-product-preview-element');
load('banner-side-by-side-element');
load('shopify-web-pixel-relay');
load('back-in-stock-pixel');
load('klaviyo-pixel');

if (location.pathname.includes('/collections/')) {
  load('collection-template');
}

init();
